/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import {
  ModalComponentWrapper,
  TableStyle,
  BorderedRow,
  GroupButton,
  ActionButton,
  SelectStyle,
} from "./styled";
import { Col } from "antd";
import { FailureBtn, NormalBtn } from "Components/Button";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import actions from "Store/Actions";
import { Notification } from "Components";

import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  // useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
// import MoveBtnGroup from "../TableResult/MoveBtnGroup";
// import { CSS } from '@dnd-kit/utilities';

const { deletedCourse, moveCourse } = actions;

const ModalDeleteCourse = ({
  listScheduleCourse,
  visibleModal,
  onOk,
  onCancel,
  setVisibleModalDelete,
  ...rest
}) => {
  const [t] = useTranslation('student');
  const dispatch = useDispatch();
  const { typeName, subject } = useSelector(
    (state) => state.student
  );
  const [paramsFilterCourse, setParamsFilterCourse] = useState({
    subject: "",
    method: "",
  });
  const [courseChecked, setCourseChecked] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showButtonSelect, setShowButtonSelect] = useState(true);
  const listScheduleCourseDelete = useMemo(() => {
    setShowButtonSelect(true);
    const { subject, method } = paramsFilterCourse;
    const newListFilter = listScheduleCourse.filter(item => {
      if (subject && method) {
        return item['科目'] === subject && item['映・添'] === method;
      }
      else if (subject) {
        return item['科目'] === subject;
      }
      else if (method) {
        return item['映・添'] === method;
      }
      return item;
    });
    return newListFilter;
}, [paramsFilterCourse]);
  const listScheduleCourseNodate = useMemo(() => {
    const newList = [...listScheduleCourseDelete]
    return newList.filter(item => Object.keys(item['TIME_UNIT']).length===0)
  }, [listScheduleCourseDelete])
  const selectCourseNodate = () => {
    const listRowKeys = listScheduleCourseNodate.map(item => item.key)
    if (showButtonSelect) {
      setCourseChecked(listScheduleCourseNodate.concat(courseChecked))
      setSelectedRowKeys(listRowKeys.concat(selectedRowKeys))
      setShowButtonSelect(false)
    }
    else {
      const filteredData = courseChecked.filter(item => !listScheduleCourseNodate.includes(item));
      const filteredKeysData = selectedRowKeys.filter(item => !listRowKeys.includes(item));
      setCourseChecked(filteredData)
      setSelectedRowKeys(filteredKeysData)
      setShowButtonSelect(true)
    }
  }
  const handleDeletedCourse = () => {
    dispatch(deletedCourse(courseChecked))
    setVisibleModalDelete(!visibleModal)
    setCourseChecked([])
    setSelectedRowKeys([])
    Notification.success(t("message.deleted_course_success"));
  }

  const COLUMN_DATA = [
    {
      title: t("student:subject"),
      dataIndex: "科目",
      key: "科目",
      width: "58px",
      align: "center",
      render: (text, record, index) => (
        <div className={record?.["映・添"] === "添削" ? "supplementary" : "not_supplement"}>
          <span>{text}</span>
          {/* {selectedRowKeys.includes(record[`番号`]) && selectedRowKeys.length === 1 && <MoveBtnGroup text={text} record={record} index={index} dataSource={listScheduleCourse} />} */}
        </div>
      ),
    },
    {
      title: t("student:film_attachment"),
      dataIndex: "映・添",
      key: "映・添",
      width: "58px",
      align: "center",
      render: (text, record, index) => (
        <div className={record?.["映・添"] === "添削" ? "supplementary" : "not_supplement"}>
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: t("student:content"),
      dataIndex: "内容",
      key: "内容",
      width: "58px",
      align: "center",
      render: (text, record, index) => (
        <div className={record?.["映・添"] === "添削" ? "supplementary" : "not_supplement"}>
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: t("student:course_name"),
      dataIndex: [`講座名`],
      key: "講座名",
      width: "280px",
      render: (text, record, index) => (
        <div className={record?.["映・添"] === "添削" ? "supplementary" : "not_supplement"}>
          <span>{text}</span>
        </div>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedKeys, selectedRows) => {
      setSelectedRowKeys(selectedKeys);
      setCourseChecked(selectedRows)
    },
    selectedRowKeys,
  };

  const deleteCourse = () => {
    handleDeletedCourse()
    onCancel();
    setSelectedRowKeys([]);
    setCourseChecked([]);
  };

  const handleCancel = () => {
    onCancel();
    setSelectedRowKeys([]);
    setCourseChecked([]);
  }
  const handleChange = () => {
    setSelectedRowKeys([]);
    setCourseChecked([]);
  };
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
  );

  const onDragEnd = ({ active, over }) => {
    if (active?.data?.current?.sortable?.index !== over?.data?.current?.sortable?.index) {
      dispatch(
        moveCourse({
          fromIndex: active?.data?.current?.sortable?.index,
          toIndex: over?.data?.current?.sortable?.index,
        })
      );
    }
  };

  // const Row = (props) => {
  //   const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
  //     id: props['data-row-key'],
  //   });
  //   const style = {
  //     ...props.style,
  //     transform: CSS.Transform.toString(
  //       transform && {
  //         ...transform,
  //         scaleY: 1,
  //       },
  //     ),
  //     transition,
  //     cursor: 'move',
  //     ...(isDragging
  //       ? {
  //           position: 'relative',
  //           zIndex: 9999,
  //         }
  //       : {}),
  //   };
  //   return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
  // };
  useEffect(() => {
    if (visibleModal === false) {
      setSelectedRowKeys([]);
    }
  }, [visibleModal]);
  return (
    <ModalComponentWrapper
      open={visibleModal}
      title={t("title_delete_course")}
      onOk={onOk}
      onCancel={handleCancel}
      destroyOnClose
      centered
    >
      <BorderedRow>
      <Col span={24}>
          <SelectStyle
            style={{ width: 90 }}
            options={subject}
            allowClear
            onChange={(e) => {
              setParamsFilterCourse({
                ...paramsFilterCourse,
                subject: e || '',
              });
              handleChange()
            }}
          />
          <SelectStyle
            style={{ width: 90 }}
            options={typeName}
            allowClear
            onChange={(e) => {
              setParamsFilterCourse({
                ...paramsFilterCourse,
                method: e || '',
              });
              handleChange()
            }}
          />
        </Col>
        <Col span={24}>
          <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
            <SortableContext
              // rowKey array
              items={listScheduleCourseDelete.map((i) => i.key)}
              strategy={verticalListSortingStrategy}
            >
              <TableStyle
                scroll={{ x: "100%", y: 400 }}
                dataSource={listScheduleCourseDelete}
                className={listScheduleCourseDelete.length > 0 ? "" : "table_nodata"}
                columns={COLUMN_DATA}
                pagination={false}
                bordered
                rowSelection={{ ...rowSelection }}
                // components={{
                //   body: {
                //     row: Row,
                //   },
                // }}
                locale={{ emptyText:t("no_data")}}
                {...rest}
              />
            </SortableContext>
          </DndContext>
        </Col>
      </BorderedRow>

      <div className="footer_btn">
        <ActionButton>
          <NormalBtn onClick={handleCancel}>キャンセル </NormalBtn>
          <FailureBtn className='delete__course-nodate' disabled={listScheduleCourseNodate.length === 0} onClick={selectCourseNodate}>
              {showButtonSelect ? t("select_delete_all_no_date") : t("unselect_delete_all_no_date")}
          </FailureBtn>
        </ActionButton>
        <GroupButton>
          <FailureBtn
            disabled={courseChecked.length === 0 }
            onClick={() => deleteCourse()}
          >
            {t("delete")}{" "}
          </FailureBtn>
        </GroupButton>
      </div>
    </ModalComponentWrapper>
  );
};

export default ModalDeleteCourse;
