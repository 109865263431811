import { Col, Row, Select, Table } from 'antd'
import ModalComponent from 'Components/Modal'
import styled from 'styled-components'

export const ModalComponentWrapper= styled(ModalComponent)`
  .ant-modal-content {
    padding: 24px 0px !important;
    
    .ant-modal-header {
      height: 44px !important;
    }

    .ant-modal-title {
      font-size: 20px !important;
      line-height: 24px !important;
    }
  }

  .footer_btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    button {
      padding: 0;
      height: 36px;
      font-size: 14px;
      min-width: 12rem;
    }
  }
`
export const ColWraper= styled(Col)`
  display: flex;
  justify-content: flex-end;
`

export const EndCol= styled(Col)`
  display: flex;
  justify-content: flex-end;
`

export const StartCol= styled(Col)`
  display: flex;
  justify-content: flex-start;
`

export const DateTimeTitle= styled.div`
  font-weight: 500;
	font-size: 1.3rem;
	line-height: 30px;
	color: #6D737A;
`

export const MidleDateTime= styled(Col)`
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
`

export const BorderedRow= styled(Row)`
  padding: 12px;
	margin-bottom: 20px;
	margin-top: 10px;
  border: 1px solid #C9C9C9;
  border-radius: 10px;

  .table_nodata {
    .ant-table {
      .ant-table-container {
        .ant-table-body {
          overflow: hidden !important;
    
          .ant-table-placeholder {
            .ant-table-cell {
              .ant-table-expanded-row-fixed {
                width: auto !important;
              }
            }
          }
        }
      }
    }
  }
`

export const TableStyle = styled(Table)`
	overflow: auto;
	height: 100%;
	
	.ant-pagination {
		display: flex;
		justify-content: center;
	}

  .ant-table-thead {
    .ant-table-cell {
      padding: 4px;
      font-size: 14px;
    }
  }

  .ant-table-tbody {
    .ant-table-cell {
      font-size: 14px;
      line-height: 16px;
    }
  }

	.ant-table {
		border-radius: 0.75rem;
		max-height: 90%;

		th{
			color: #6D737A !important;
			font-weight: 400;
			font-size: 12px;
			line-height: 14px;

		}

		tbody{
			/* min-height: 28px !important; */
			td{
				/* padding: 0 !important; */
				font-weight: 400;
				font-size: 16px;
				line-height: 19px;
			}
		}
		
		.ant-table-tbody > tr > td {
			height: 5px;
			padding: 2px !important;
		}

		.ant-table-cell::before{
				background-color: transparent !important;
				content: "" !important;
			}

    .ant-table-cell:nth-child(3),
    .ant-table-cell:nth-child(4),
    .ant-table-cell:nth-child(5) {
      padding: 0;
    }

		.ant-table-content {
			border-radius: 0.75rem;
		}

    .ant-table-container {
      border: 1px solid #f0f0f0;
    }
	}

  .supplementary {
		background: #00bfff;
    padding: 5px;
    height: 100%;
	}

	.not_supplement {
    padding: 5px;
	}
`;


export const SelectStyle= styled(Select)`
  margin-right: 10px;
  margin-bottom: 12px;

  .ant-select-selector{
    height: 29px;
    color: #6D737A;
  }
`
export const ActionButton = styled.div`
  display: flex;
  .delete__course-nodate{
    margin-left: 20px;
    padding: 0 10px !important;
  }
`
export const GroupButton = styled(Col)`
  display: flex;
  justify-content: flex-end;

   @media only screen and (max-width: 992px) {
    display: flex;
    flex-direction: column;

    button{
      margin-top: 1rem;
      padding: 0 14px;
      width: 45px;
    }
  }

`